import React from 'react';
import '../App.css';

function CallToAction() {
  return (
    <section className="cta" id="get-started">
      <div className="container">
        <h2>Ready to Game Smarter?</h2>
        <a href="/signup" className="btn">Sign Up for $3.99/month</a>
      </div>
    </section>
  );
}

export default CallToAction;
