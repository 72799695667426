import React from 'react';
import HeroSection from '../components/HeroSection';
import Features from '../components/Features';
import Pricing from '../components/Pricing';
import HowItWorks from '../components/HowItWorks';
import Testimonials from '../components/Testimonials';
import CallToAction from '../components/CallAction';

function Home() {
  return (
    <div>
      <HeroSection />
      <Features />
      <Pricing />
      <HowItWorks />
      <Testimonials />
      <CallToAction />
    </div>
  );
}

export default Home;
