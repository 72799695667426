import React from 'react';
import '../App.css';
import logo from '../images/pishu-logo.png';

function Footer() {
  return (
    <footer id="contact">
      <div className="container">
        <div className="footer-content">
          <div className="footer-logo">
            <img src={logo} alt="Pishu Logo" />
          </div>
          <div className="social-media">
            <a href="#"><i className="fa-brands fa-facebook-f"></i></a>
            <a href="#"><i className="fa-brands fa-twitter"></i></a>
            <a href="#"><i className="fa-brands fa-instagram"></i></a>
            <a href="#"><i className="fa-brands fa-discord"></i></a>
          </div>
          <p>&copy; 2023 Pishu. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
