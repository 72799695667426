import React from 'react';
import '../App.css';

function HowItWorks() {
  return (
    <section className="how-it-works" id="how-it-works">
      <div className="container">
        <h2>How It Works</h2>
        <div className="steps">
          <div className="step">
            <span>1</span>
            <h3>Create an Account</h3>
            <p>Sign up and choose your subscription plan to get started.</p>
          </div>
          <div className="step">
            <span>2</span>
            <h3>Download Pishu</h3>
            <p>Get the app for your desktop and install it in seconds.</p>
          </div>
          <div className="step">
            <span>3</span>
            <h3>Start Playing</h3>
            <p>Pishu sits on your screen ready to assist you with in-game questions as you play.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;
