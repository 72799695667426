import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase'; // Import Firebase auth and Firestore

// Firebase configuration


function SignUp() {
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const email = e.target.email.value.trim();
    const password = e.target.password.value;
    const confirmPassword = e.target['confirm-password'].value;

    // Clear previous error message
    setErrorMessage('');

    // Validate password match
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    try {
      // Create user with Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      console.log('User registered:', userCredential.user);

      // Redirect to dashboard or success page
      window.location.href = '/dashboard';
    } catch (error) {
      console.error('Error during sign up:', error);
      let message = '';
      switch (error.code) {
        case 'auth/email-already-in-use':
          message = 'The email address is already in use.';
          break;
        case 'auth/invalid-email':
          message = 'Invalid email address format.';
          break;
        case 'auth/weak-password':
          message = 'Password should be at least 6 characters.';
          break;
        default:
          message = 'An unexpected error occurred. Please try again later.';
          break;
      }
      setErrorMessage(message);
    }
  };

  return (
    <section className="signup-section">
      <div className="container">
        <h2>Create Your Account</h2>
        <form id="signup-form" onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="email">Email Address</label>
            <input type="email" id="email" name="email" placeholder="Enter your email" required />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" placeholder="Create a password" required />
          </div>
          <div className="input-group">
            <label htmlFor="confirm-password">Confirm Password</label>
            <input type="password" id="confirm-password" name="confirm-password" placeholder="Confirm your password" required />
          </div>
          <button type="submit" className="btn">Sign Up</button>
          <p className="error-message">{errorMessage}</p>
        </form>
        <p>Already have an account? <a href="/login">Log in here</a>.</p>
      </div>
    </section>
  );
}

export default SignUp;
