import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase'; // Import Firebase auth

function Login() {
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const email = e.target.email.value.trim();
    const password = e.target.password.value;

    // Clear previous error message
    setErrorMessage('');

    try {
      // Sign in with Firebase Auth
      await signInWithEmailAndPassword(auth, email, password);

      // Redirect to dashboard upon successful login
      window.location.href = '/dashboard';
    } catch (error) {
      console.error('Error during login:', error);
      let message = '';
      switch (error.code) {
        case 'auth/user-not-found':
          message = 'No user found with this email address.';
          break;
        case 'auth/wrong-password':
          message = 'Incorrect password.';
          break;
        case 'auth/invalid-email':
          message = 'Invalid email address format.';
          break;
        default:
          message = 'An unexpected error occurred. Please try again later.';
          break;
      }
      setErrorMessage(message);
    }
  };

  return (
    <section className="signup-section">
      <div className="container">
        <h2>Log In to Your Account</h2>
        <form id="login-form" onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="email">Email Address</label>
            <input type="email" id="email" name="email" placeholder="Enter your email" required />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" placeholder="Enter your password" required />
          </div>
          <button type="submit" className="btn">Log In</button>
          <p className="error-message">{errorMessage}</p>
        </form>
        <p>Don't have an account? <a href="/signup">Sign up here</a>.</p>
      </div>
    </section>
  );
}

export default Login;
