import React from 'react';

function Success() {
  return (
    <section className="success-section">
      <div className="container">
        <h2>Subscription Successful!</h2>
        <p>Thank you for subscribing to Pishu.</p>
        <p>We've sent you an email to set up your account password.</p>
      </div>
    </section>
  );
}

export default Success;
