// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBIy_tmC7QKK0XFVy18kn3WmKIM6e6edRg",
  authDomain: "pishu-6ce86.firebaseapp.com",
  projectId: "pishu-6ce86",
  storageBucket: "pishu-6ce86.firebasestorage.app",
  messagingSenderId: "673896887358",
  appId: "1:673896887358:web:6cbe20404a9ca48e220d6f",
  measurementId: "G-64NKWLX4NM"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
