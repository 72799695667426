import React from 'react';
import '../App.css';

function Pricing() {
  return (
    <section className="pricing" id="pricing">
      <div className="container">
        <h2>Simple Pricing</h2>
        <div className="pricing-cards">
          <div className="pricing-card">
            <div className="pricing-card-header">
              <h3>Monthly Subscription</h3>
              <p className="price">$3.99<span>/month</span></p>
            </div>
            <ul>
              <li>Unlimited Access to Pishu</li>
              <li>Instant In-Game Assistance</li>
              <li>Regular Updates and New Features</li>
              <li>Priority Customer Support</li>
            </ul>
            <a href="signup.html" className="btn">Subscribe Now</a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing;
