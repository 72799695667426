import React from 'react';
import '../App.css';

function HeroSection() {
  return (
    <section className="hero" id="home">
      <div className="container">
        <div className="hero-text">
          <h1>Meet Pishu, Your In-Game AI Helper</h1>
          <p>Spend more time playing and less time searching. Get instant answers to your in-game questions without leaving your game, all for just $3.99/month.</p>
          <a href="signup.html" className="btn">Download Pishu Ai</a>
        </div>
        <div className="hero-video">
          <div className="video-container">
            <iframe src="https://www.youtube.com/embed/25gQyvRwXHk" title="Pishu AI Assistant Demo" frameBorder="0" allowFullScreen></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
