import React from 'react';
import '../App.css';
import logo from '../images/pishu-logo.png';

function Header() {
  return (
    <header>
      <div className="container">
        <div className="logo">
          <img src={logo} alt="Pishu Logo" />
        </div>
        <nav>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="#how-it-works">How It Works</a></li>
            <li><a href="/login">Log In</a></li>
            <li><a href="/signup" className="btn">Sign Up</a></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
