import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { auth, db } from '../firebase'; // Import Firebase auth and Firestore



function Dashboard() {
  const [subscriptionStatus, setSubscriptionStatus] = useState('Checking subscription status...');
  const [showSubscribeButton, setShowSubscribeButton] = useState(false);
  const [showManageSubscriptionButton, setShowManageSubscriptionButton] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [subscriptionCancelAtPeriodEnd, setSubscriptionCancelAtPeriodEnd] = useState(false);
  const [subscriptionCancelAt, setSubscriptionCancelAt] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);

        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            const subscriptionActive = userData.subscriptionActive;
            const cancelAtPeriodEnd = userData.subscriptionCancelAtPeriodEnd || false;
            const cancelAtTimestamp = userData.subscriptionCancelAt
              ? userData.subscriptionCancelAt.toDate()
              : null;

            setSubscriptionCancelAtPeriodEnd(cancelAtPeriodEnd);
            setSubscriptionCancelAt(cancelAtTimestamp);

            if (subscriptionActive) {
              if (cancelAtPeriodEnd) {
                // Subscription is active but set to cancel at the end of the period
                const formattedDate = cancelAtTimestamp
                  ? cancelAtTimestamp.toLocaleDateString()
                  : 'the end of the billing period';
                setSubscriptionStatus(`Your subscription will end on ${formattedDate}.`);
              } else {
                setSubscriptionStatus('Your subscription is active.');
              }
              setShowSubscribeButton(false);
              setShowManageSubscriptionButton(true);
            } else {
              setSubscriptionStatus('You do not have an active subscription.');
              setShowSubscribeButton(true);
              setShowManageSubscriptionButton(false);
            }
          } else {
            setSubscriptionStatus('Subscription status unknown.');
            setShowSubscribeButton(true);
            setShowManageSubscriptionButton(false);
          }
        } catch (error) {
          console.error('Error checking subscription:', error);
          setSubscriptionStatus('Error checking subscription status.');
        }
      } else {
        window.location.href = '/login';
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSubscription = async () => {
    if (currentUser) {
      const uid = currentUser.uid;

      try {
        const response = await fetch('https://nodeserver-fcdggqf5bcc4bsd4.eastus2-01.azurewebsites.net/create-checkout-session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uid }),
        });

        const data = await response.json();
        if (response.ok) {
          window.location.href = data.url;
        } else {
          console.error('Server error:', data.error);
          alert('Error: ' + data.error);
        }
      } catch (error) {
        console.error('Fetch error:', error);
      }
    }
  };

  const handleCustomerPortal = async () => {
    if (currentUser) {
      const uid = currentUser.uid;

      try {
        const response = await fetch('https://nodeserver-fcdggqf5bcc4bsd4.eastus2-01.azurewebsites.net/create-customer-portal-session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uid }),
        });

        const data = await response.json();
        if (response.ok) {
          window.location.href = data.url;
        } else {
          console.error('Server error:', data.error);
          alert('Error: ' + data.error);
        }
      } catch (error) {
        console.error('Fetch error:', error);
      }
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      window.location.href = '/';
    } catch (error) {
      console.error('Error during sign out:', error);
    }
  };

  return (
    <section className="dashboard-section">
      <div className="container">
        <h2>Welcome to Your Dashboard</h2>
        <p>{subscriptionStatus}</p>
        {showSubscribeButton && (
          <button onClick={handleSubscription} className="btn">
            Subscribe Now
          </button>
        )}
        {showManageSubscriptionButton && (
          <button onClick={handleCustomerPortal} className="btn">
            Manage Subscription
          </button>
        )}
        <button onClick={handleLogout} className="btn" id="logout-button">
          Log Out
        </button>
      </div>
    </section>
  );
}

export default Dashboard;