import React from 'react';
import '../App.css';

function Features() {
  return (
    <section className="features" id="features">
      <div className="container">
        <h2>Why Choose Pishu?</h2>
        <div className="feature-cards">
          <div className="card">
            <i className="fa-solid fa-magnifying-glass"></i>
            <h3>Instant In-Game Answers</h3>
            <p>Access crafting recipes, quest guides, and item locations without pausing your game.</p>
          </div>
          <div className="card">
            <i className="fa-solid fa-gamepad"></i>
            <h3>Play More, Search Less</h3>
            <p>Pishu helps you stay immersed by providing quick answers, reducing the need to Google.</p>
          </div>
          <div className="card">
            <i className="fa-solid fa-brain"></i>
            <h3>AI-Powered Insights</h3>
            <p>Utilizes advanced AI to provide strategies and insights that adapt to your gaming style.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
