import React from 'react';

function CreateAccount() {
  return (
    <section className="signup-section">
      <div className="container">
        <h2>Create Your Account</h2>
        <form id="signup-form">
          <div className="input-group">
            <label htmlFor="email">Email Address</label>
            <input type="email" id="email" placeholder="Enter your email" required />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" placeholder="Create a password" required />
          </div>
          <div className="input-group">
            <label htmlFor="confirm-password">Confirm Password</label>
            <input type="password" id="confirm-password" placeholder="Confirm your password" required />
          </div>
          <button type="submit" className="btn">Create Account</button>
        </form>
        <p>Already have an account? <a href="/login">Log in here</a>.</p>
      </div>
    </section>
  );
}

export default CreateAccount;
