import React from 'react';
import '../App.css';

function Testimonials() {
  return (
    <section className="testimonials" id="testimonials">
      <div className="container">
        <h2>What Gamers Are Saying</h2>
        <div className="testimonial-cards">
          <div className="testimonial">
            <p>"Pishu saves me so much time! No more tabbing out to look up recipes in Minecraft."</p>
            <h4>- Alex R.</h4>
          </div>
          <div className="testimonial">
            <p>"I love how Pishu helps me find items in games without breaking my immersion."</p>
            <h4>- Jamie L.</h4>
          </div>
          <div className="testimonial">
            <p>"As a RuneScape player, Pishu's training tips have been invaluable. Highly recommended!"</p>
            <h4>- Sam K.</h4>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
